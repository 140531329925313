@tailwind base;
@tailwind components;
@tailwind utilities;

.button {
  @apply text-white border-2 px-8 py-2 my-2 items-center hover:bg-[#FDB515] hover:border-[#FDB515] rounded-lg;
}

.input {
  @apply w-full p-2 my-2 bg-transparent border-2 border-white rounded-lg outline-none focus:border-[#FDB515];
}

.title {
  @apply text-2xl font-bold md:text-4xl border-b-4 border-[#FDB515];
}
